import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TranslateModule as NGXTranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import {
  createBusinessAwareLoader,
  BusinessProvider,
} from './BusinessAwareTranslateLoader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

const DEFAULT_LANGUAGE = 'en';
const SUPPORTED_LANGUAGES = ['en', 'da', 'de', 'fr', 'se'];
const SUPPORTED_LANGUAGES_REGEXP = new RegExp(
  `^${SUPPORTED_LANGUAGES.join('|')}`
);

export function persistLanguage(language: string) {
  window.localStorage.setItem('language', language);
}

const getFromUrl = (): string | null => {
  const params = new URLSearchParams(window.location.search);
  return params.get('language');
};

const getFromLocalStorage = (): string | null => {
  return window.localStorage.getItem('language');
};

export function getPersistedLanguage(fallback = DEFAULT_LANGUAGE) {
  let selectedLanguage = getFromUrl() || getFromLocalStorage();
  if (!selectedLanguage) {
    selectedLanguage = fallback;
  }
  if (!SUPPORTED_LANGUAGES_REGEXP.test(selectedLanguage)) {
    console.log('Unsupported language', selectedLanguage);
    selectedLanguage = DEFAULT_LANGUAGE;
  }
  return selectedLanguage;
}

export function initTranslation(translateService: TranslateService) {
  translateService.addLangs(SUPPORTED_LANGUAGES);
  translateService.setDefaultLang(DEFAULT_LANGUAGE);
  const selectedLanguage = getPersistedLanguage(
    translateService.getBrowserLang() || 'en'
  );
  return translateService.use(selectedLanguage);
}

// Configuring sharing
//
// https://github.com/ngx-translate/core/issues/209
//
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NGXTranslateModule.forChild({
      defaultLanguage: 'en',
      isolate: true,
      loader: {
        provide: TranslateLoader,
        useFactory: createBusinessAwareLoader,
        deps: [HttpClient, BusinessProvider],
      },
    }),
  ],
  providers: [{ provide: BusinessProvider, useClass: BusinessProvider }],
  exports: [CommonModule, NGXTranslateModule],
})
export class TranslateModule {
  constructor(translateService: TranslateService) {
    initTranslation(translateService);
  }
}
