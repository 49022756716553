import { Model, Nullable } from './helpers';

export type Credentials = {
  email: string;
  password: string;
  customer_alias: string;
  version: string;
};

export type CommonLanguage = Model & {
  /** example: Danish */
  name: string;
  /** example: Dansk */
  description: Nullable<string>;
  /** Pre-selected in UI */
  is_default: boolean;
  is_for_translate: boolean;
  /** example: da */
  language_code: string;
  /** example: da-DK */
  iso_language_code: string;
};

export type User = Model & {
  email: string;
  first_name: string;
  last_name: string;
  defaultCountryCode: string;
  token: string;
};

export type Customer = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  alias: string;
  subdomain: string;
  company_name: string;
  address: string;
  city: string;
  zip: string;
  country: string;
  country_code: string;
  phone_number: string;
  registration_date: Date;
  is_tos_accepted: boolean;
  is_newsletter_accepted: boolean;
  is_active: boolean;
  aws_account_id: string;
  created_at: Date;
  updated_at: Date;
};

export enum BrikId {
  Admin = 'admin',
  Allo = 'allo',
  Contact = 'contact',
  Dashboard = 'dashboard',
  File = 'file',
  Stak = 'stak',
  Survey = 'survey',
  Trust = 'trust',
}

export type Brik = {
  assign_date: Date;
  brik_id: BrikId;
  business: string;
  created_at: Date;
  description: string;
  id: string;
  is_active: number;
  pricelist_id: string;
  subscription_id: number;
  updated_at: Date;
  user_id: string;
};

export type CommonEmailList = Model & {
  name: string;
  description: Nullable<string>;
  updated_by: Nullable<string>;
  is_verified: boolean;
  is_forward: boolean;
};

export type CommonEmailListMapped = Model & {
  email: string;
  name: Nullable<string>;
  updated_by: Nullable<string>;
  is_verified: boolean;
  is_forward: boolean;
};

export type CommonContact = Model & {
  address: Nullable<string>;
  city: Nullable<string>;
  country: Nullable<string>;
  country_code: Nullable<string>;
  email: Nullable<string>;
  lat: Nullable<number>;
  lng: Nullable<number>;
  name: Nullable<string>;
  phone: Nullable<string>;
  zip: Nullable<string>;
};

export type HelpItem = {
  title: string;
  content: string;
  brik: BrikId;
  show?: boolean;
};

export type CommonFeature = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
};
