import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import {
  Create,
  CreateTrustFeedback,
  CreateTrustForm,
  PaginatedResponse,
  PaginationRequest,
  TrustCategory,
  TrustFeedback,
  TrustForm,
  TrustFormDesign,
  TrustFormField,
  Update,
  UpdateTrustForm,
} from '@softbrik/data/models';
import { DOCUMENT } from '@angular/common';
import { createParams } from './utils';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  StorageKeyHandler,
  createKeyHandler,
  StorageType,
} from '@softbrik/shared/helpers';
import { AppService } from './app.service';
import { uniqBy } from 'lodash';

export const KEELLS_DROPDOWN = [
  'Aththidiya, Keells Super, #16/A, Aththidiya Road, Rathmalana.',
  'Athurugiriya, Keells Super, #70/20, Main Street, Athurugiriya.',
  'Borella, Keells Super, #85, Cotta Road, Borella.',
  'Crescat, Keells Super, Crescat Boulevard, #89 B, Kollupitiya Road, Colombo 03.',
  'Galle, Keells Super, # 46, Colombo Rd, Galle',
  'Gampaha, Keells Super, #17 A, Queen Mary Road, Gampaha.',
  'Ja-Ela, Keells Super, #170 C, Colombo Road, Ja-Ela.',
  'Kadawatha, Keells Super, #25, Kandy Road, Kadawatha.',
  'Kandana, Keells Super, # 176, Nagoda Road, Kandana.',
  'Kottawa, Keells Super, #114, Piliyandala Road, Kottawa.',
  'Kotte, Keells Super, #581, Kotte Road, Pitakotte.',
  'Kurunagala, Keells Super, #138, Colombo Road, Kurunagala.',
  'Liberty Plaza, Keells Super, B/2, Liberty Plaza, Colombo 03.',
  'Mahabage, Keells Super, #588/1, Negombo Road, Mahabage, Ragama.',
  'Malabe, Keells Super, # 867, Kaduwela Road, Malabe.',
  'Marine Drive, Keells Super, #35, Upathissa Road, Bambalapitiya, Colombo 4',
  'Matara, Keells Super, # 227, Anagarika Darmapala Mw, Nupe, Matara',
  'Moratuwa, Keells Super, #491, Galle Road, Rawathawatte, Moratuwa.',
  'Mt. Lavinia, Keells Super, #388 Galle Road, Mt. Lavinia.',
  'Narahenpita, Keells Super, #104, Nawala Road, Narahenpita.',
  'Nawala, Keells Super, #459, Nawala Road, Rajagiriya.',
  'Negombo, Keells Super, #41, Archibishop Nocholas Marcus Fernando Mawatha, Negombo.',
  'Panadura, Keells Super, #445, Galle Road, Panadura.',
  'Pelawatta, Keells Super, #732/1, Pannipitiya Road, Pelawatta.',
  'Peliyagoda, Keells Super, #407, Kandy Road, Peliyagoda, Kelaniya.',
  'Pepiliyana, Keells Super, #3 Bellantara Road, Pepiliyana. Nedimala , Dehiwala.',
  'Stanly Thilakarathna Mw, Keells Super, #225, Stanly Thilakarathna Mw, Nugegoda.',
  'Thalawathugoda, Keells Super, #531, Capital mall, Madiwela Road, Thalawathugoda.',
  'Union Place, Keells Super, #199, Union Place, Colombo -02',
  'Wattala, Keells Super, #385, Negombo Road, Wattala.',
  'Wijerama, Keells Super, #4/6, Soratha Mw, Wijerama, Nugegoda.',
];

export const YALLO_DROPDOWN = [
  'iPhone',
  'Android Galaxy Phone',
  'Android Other Phone',
  'Strong Box',
  'Mi Box',
  'Desktop Chrome',
  'Desktop Firefox',
  'Desktop Safari',
  'Desktop Other Browser',
  'Mobile Chrome',
  'Mobile Firefox',
  'Mobile Safari',
  'Mobile Other Browser',
  'Other Device',
];

export const YALLO_DROPDOWN_2 = ['Feature', 'Bug'];

@Injectable({
  providedIn: 'root',
})
export class TrustService {
  // public API_LINK = `https://rc6g6rtra7.execute-api.eu-west-1.amazonaws.com/prod`;
  public API_LINK: string = '';

  public mailingList: unknown;

  public store: StorageKeyHandler = createKeyHandler(
    'trust',
    StorageType.LOCAL
  );
  public session: StorageKeyHandler = createKeyHandler(
    'trust',
    StorageType.SESSION
  );

  categories: Observable<PaginatedResponse<TrustCategory>>;

  constructor(
    private http: HttpClient,
    private app: AppService,
    @Inject(DOCUMENT) public document: Document
  ) {
    if (!this.API_LINK) {
      this.API_LINK = localStorage.getItem('TRUST_API_LINK');
    }
    this.refreshCategories();
  }

  getDesigns(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<PaginatedResponse<TrustFormDesign>>(
      `${this.API_LINK}/feedback-types-designs?${query}`
    );
  }

  getUsedDesigns() {
    // TODO create a backend for this instead
    return this.getForms({
      count: 999,
      filter: JSON.stringify({ is_active: true }),
    }).pipe(
      switchMap((response) => {
        const designs = uniqBy(
          response.data
            .map((form) => form.trust_brik_feedback_type_design)
            .filter(Boolean),
          'id'
        );
        designs.sort((a, b) => a.language_id - b.language_id);
        return of(designs);
      })
    );
  }

  getForm(id: number) {
    return this.http.get<TrustForm>(`${this.API_LINK}/items/${id}`);
  }

  getForms(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<PaginatedResponse<TrustForm>>(
      `${this.API_LINK}/items?${query}`
    );
  }

  createForm(form: CreateTrustForm) {
    return this.http.post<TrustForm>(`${this.API_LINK}/items`, form);
  }

  updateForm(form: UpdateTrustForm) {
    return this.http.put<TrustForm>(`${this.API_LINK}/items/${form.id}`, form);
  }

  updateFeedbacks(feedbacks: Update<TrustFeedback>[]) {
    return this.http.put(`${this.API_LINK}/feedback/bulk`, feedbacks);
  }

  updateFeedback(feedback: Update<TrustFeedback>) {
    return this.http.put<TrustFeedback>(
      `${this.API_LINK}/feedback/${feedback.id}`,
      feedback
    );
  }

  updateFeedbackLanguage(form: TrustForm, feedback_language_id: number) {
    const { id: item_id } = form;
    return this.http.post(`${this.API_LINK}/items/translate-feedback/`, {
      feedback_language_id,
      item_id,
    });
  }

  createFormQRLink({
    formId,
    language,
    rating,
  }: {
    formId: number;
    language: string;
    rating?: number;
  }) {
    return this.app.createPublicURL(`trust-feedback`, {
      form: formId.toString(),
      language,
      ...(String(rating) !== '' && Number.isInteger(Number(rating))
        ? { rating: rating.toString() }
        : {}),
    });
  }

  getFeedbackForForm(formId: number, params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<PaginatedResponse<TrustFeedback>>(
      `${this.API_LINK}/items/${formId}/feedbacks?${query}`
    );
  }

  markFeedbackAsRead(feedbackId: number) {
    return this.http.patch<unknown>(
      `${this.API_LINK}/feedback/${feedbackId}/mark-as-read`,
      null
    );
  }

  getFeedbackById(formId: number, feedbackId: number) {
    return this.getFeedbackForForm(formId, {
      filter: JSON.stringify({
        id: feedbackId,
      }),
    }).pipe(
      map((result) => {
        return result.data.pop();
      })
    );
  }

  getTagsForForm(formId: number) {
    const params = createParams({
      count: 999,
    });
    return this.http.get<PaginatedResponse<string>>(
      `${this.API_LINK}/items/${formId}/tags?${params}`
    );
  }

  createFormField(field: Create<TrustFormField>) {
    return this.http.post<TrustFormField>(
      `${this.API_LINK}/feedback-type-items`,
      field
    );
  }

  deleteFormFields(formId: number) {
    return this.http.delete<void>(
      `${this.API_LINK}/feedback-type-items/${formId}`
    );
  }

  getFormFields(formId: number, params: PaginationRequest) {
    const subdomain = this.app.customerAlias;
    const query = createParams(params, { id: formId, subdomain });
    return this.http.get<PaginatedResponse<TrustFormField>>(
      `${this.API_LINK}/feedback-type-items?${query}`
    );
  }

  getActiveFormFields(formId: number) {
    return this.getFormFields(formId, {
      count: 999,
      sortKey: 'order_no',
      sortDirection: 'asc',
      filter: JSON.stringify({
        item_id: formId,
        is_active: true,
      }),
    });
  }

  formIsNps(form: TrustForm, fields?: TrustFormField[]) {
    // Ideally we check a forms types and then we'll know for certain
    // if the form is a NPS or not
    if (fields) {
      return fields.some((typeItem) => {
        return (
          typeItem.description === 'nps' && typeItem.type === 'StarRatingType'
        );
      });
    }

    // If we do not have access to the type items, we'll check the form design
    // and form name as a approximation of the type
    return Boolean(
      form.trust_brik_feedback_type_design?.name
        ?.toLowerCase()
        ?.includes('nps') || form?.name?.toLowerCase()?.includes('nps')
    );
  }

  createFeedback(feedback: CreateTrustFeedback) {
    return this.http.post<{ id: number; item_id: number; uploadUrl?: string }>(
      `${this.API_LINK}/feedback`,
      feedback
    );
  }

  getSignedUrl(type: string) {
    return this.http.get<{ uploadUrl: string; fileName: string }>(
      `${this.API_LINK}/multi-reply-audio-url?content=${encodeURIComponent(
        type
      )}`
    );
  }

  uploadAudioFeedback(link: string, feedback: Blob) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': feedback.type,
      }),
    };
    return this.http.put(link, feedback, options);
  }

  getFile(id: any, isDownload: boolean) {
    return this.http.post(`${this.API_LINK}/get-file`, { id, isDownload });
  }

  getForwardFeedbackUrl(id: number, type: string) {
    return this.http.get<{ uploadUrl: string; fileName: string }>(
      `${
        this.API_LINK
      }/forward-multi-reply-audio-url?id=${id}&content=${encodeURIComponent(
        type
      )}`
    );
  }

  forwardFeedback(params: {
    /** email */
    to: string;
    ids: number[];
    subject: string;
    text: string;
    fileName?: string;
    item_id: number;
  }) {
    return this.http.post<void>(`${this.API_LINK}/forward-multi-reply`, params);
  }

  multiReply({
    recipients,
    ...rest
  }: {
    item_id: number;
    subject: string;
    text: string;
    from: string;
    copyto: string | null;
    recipients: string[];
    language_id?: number | null;
    status_id?: number | null;
    category_id?: number | null;
    severity_id?: number | null;
    comment?: string | null;
    fileName?: string | null;
  }) {
    return this.http.post(`${this.API_LINK}/multi-reply`, {
      ...rest,
      bcc: recipients.join(','),
    });
  }

  createStakIssue(issue: any) {
    const link = localStorage.getItem('STAK_API_LINK');
    return this.http.post(`${link}/trust-issues`, issue);
  }

  refreshCategories() {
    this.categories = this.getCategories({
      offset: 0,
      count: 1000,
      sortKey: 'name',
      sortDirection: 'asc',
      filter: JSON.stringify({ is_active: true }),
    });
  }

  getCategories(params: PaginationRequest = {}) {
    if (!params.filter) {
      params.filter = JSON.stringify({ is_active: true });
    }
    const query = createParams(params);
    return this.http.get<PaginatedResponse<TrustCategory>>(
      `${this.API_LINK}/categories?${query}`
    );
  }

  createCategory(category: Create<TrustCategory>) {
    return this.http.post<TrustCategory>(
      `${this.API_LINK}/categories`,
      category
    );
  }

  updateCategory(category: Update<TrustCategory>) {
    return this.http.put<TrustCategory>(
      `${this.API_LINK}/categories/${category.id}`,
      category
    );
  }
}
