<div class="dashboard container is-fluid">
  <h3
    class="mt-4 title is-5"
    translate="dashboard.Welcome, {customer}"
    [translateParams]="{ customer: customerName }"
  ></h3>

  <ng-template #loading>
    <div class="dashboard__loader animate-pulse" translate>common.Loading</div>
  </ng-template>

  <div *ngIf="briksAll | async; else loading" class="briks">
    <div class="briks__group">
      <softbrik-brik-card
        (click)="openBrik(brik.brik_id)"
        *ngFor="let brik of briks | async"
        [brikid]="brik.brik_id"
        [briktitle]="'briks.' + brik.description | translate"
      >
      </softbrik-brik-card>
    </div>

    <hr />

    <div class="briks__group">
      <softbrik-brik-card
        (click)="openBrik(brik.brik_id)"
        *ngFor="let brik of briksAdmin | async"
        [brikid]="brik.brik_id"
        [briktitle]="'briks.' + brik.description | translate"
      >
      </softbrik-brik-card>
    </div>
  </div>
</div>
