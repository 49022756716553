<nav
  class="menu"
  [class.menu--collapsed]="collapsed"
  [class.menu--collapsed--complete]="collapsedComplete"
>
  <div class="menu__slide">
    <div class="menu__header is-clickable">
      <div class="menu__header__brand is-clickable" (click)="home()">
        <img class="menu__header__logo" [src]="logo" />
        <span class="menu__header__name" id="brikId">{{ currentBrik }}</span>
      </div>
      <div class="menu__header__toggler" (click)="toggleCollapsed()">
        <div class="span icon"><i class="fa fa-chevron-left"></i></div>
      </div>
    </div>

    <ul>
      <li *ngFor="let item of items">
        <div class="menu-label">{{ item.name }}</div>
        <ul class="menu-list">
          <li *ngFor="let sub of item.values">
            <a (click)="navigate(sub)">{{ sub.name }}</a>
          </li>
        </ul>
      </li>
    </ul>

    <!-- ul>
      <li *ngFor="let item of briksCommon" (click)="openBrik(item)">
        <img class="menu__header__logo" [src]="item.logo" />
        {{ item.brik_id }}
      </li>
      <hr *ngIf="briksCommon.length && briksAdmin.length" />
      <li *ngFor="let item of briksAdmin" (click)="openBrik(item)">
        <img class="menu__header__logo" [src]="item.logo" />
        {{ item.brik_id }}
      </li>
    </ul -->

    <div id="sidebar-portal"></div>

    <div
      *ngIf="!collapsed && isSandbox"
      class="email-notice message is-warning"
    >
      <div class="message-body content">
        <p>
          {{ 'component.sidebar.Account verfication pending' | translate }}
          <a href="/trust/email-notifications" translate
            >component.sidebar.See more</a
          >
        </p>
      </div>
    </div>
  </div>
</nav>
